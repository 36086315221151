.advanced-file-upload-minister {
  &__preview-detail {
    max-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 20px;

    img {
      width: auto;
      height: 160px;
      border-radius: 10px;
      margin-bottom: 20px;
    }
  }

  &__preview-default {
    width: 100px;
    height: 160px;
    border-radius: 10px;
    background-color: #F9F9F9;
    border: #9B9AA3 4px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  &__container-card {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: auto;
      height: 100px;
      border-radius: 10px;
      margin-bottom: 20px;
    }
  }

  &__title {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  &__preview-card {
    height: 100px;
    width: 160px;
    border-radius: 10px;
    background-color: #F9F9F9;
    border: #9B9AA3 4px solid;
    display: flex;
    align-items: start;
    justify-content: start;
    margin-bottom: 20px;
    padding: 5px;
  }

  &__preview-card-icon {
    font-size: 48px;
    color: #9B9AA3;
  }

  &__preview-icon {
    font-size: 56px;
    color: #9B9AA3;
  }
}
