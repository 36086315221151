.panel-sidebar {
  width: 240px;
  border-right: 1px solid @gray-border;

  &__name {
    margin: 0 auto;
    justify-content: center;
    display: flex;
  
   &__span {
     font-weight: bold;
   }
  }

  &__logo {
    padding: 30px;

    &__img {
      max-width: 65%;
      margin: 0 auto;
      display: flex;
    }
  }
}
