.transparent-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  outline: 0 !important;
  width: auto;
  height: auto;

  &:disabled {
    cursor: default;
  }
}

.ql-editor{
  min-height:200px;
}

.form-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 30px;
  border-top: 1px solid @border-color;
  padding-top: 30px;

  &__single {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.MuiDataGrid-colCell {

  .MuiDataGrid-columnSeparator {
    display: none !important;
  }
}

.MuiDataGrid-cell {
  border-right: none !important;
}

.MuiDataGrid-row.Mui-even {
  padding-right: 0 !important;
}

.text-right {
  text-align: right;
  align-self: flex-end;
}

.report {

  &__header {
  }

  &__text-area{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__button{
      align-self: center;
    }
  }

  &__image-table {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__csv-file-input {
    margin-top: 1.5rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__total-users {
      list-style-type: none;
      padding: 0;
    }
  }

  &__csv-table {
    overflow-y: scroll;
    height: 50%;

    &::-webkit-scrollbar{
      width: 5px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #A70228;    
      border-radius: 1rem;
    }

    &__csv-table-result {  
      display: grid;
      height: 30vh;

      &__table-error, &__table-header {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
      }
  
      &__table, td, th {
        width: 100%;
        text-align: left;
      } 
      
    }
  }

  &__table {

    &__inner {
      height: 680px;
    }
  }
}


.details {

  &__title {
    padding-top: 30px;

    &__subtext {
      font-size: 12pt;
      font-weight: bold;
      margin-bottom: 0;
    }

    &__text {
      font-size: 15pt;
      font-weight: bold;
      margin-bottom: 0;
    }

    &__description {
      margin-bottom: 30px;
    }
  }

  &__form {

    &__actions {
      border-top: 1px solid @gray-border;
      margin-top: 15px;
      padding-top: 30px;

      &__row{
        display: flex;
        justify-content: space-between;
      }
    }

    &__subtitle {
      padding-top: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid @gray-border;
    }

    &__item {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
}
