html,
body {
  font-family: 'Poppins', sans-serif !important;
}

@import '~antd/dist/antd.less';

@import './_parameters';
@import './_globals';

// stacks
@import 'src/app/containers/Panel/PanelNavigationStack';

// pages
@import 'src/app/pages/Auth/Login/Login';
@import 'src/app/pages/Auth/PasswordRecovery/PasswordRecovery';
@import 'src/app/pages/Activities/Notifications/Details/NotificationsDetails';
@import 'src/app/pages/NotFound/NotFound';
@import 'src/app/pages/Worship/Cult/Details/CultDetails';

// components
@import 'src/app/components/AdvancedInput/AdvancedInput';
@import 'src/app/components/AdvancedButton/AdvancedButton';
@import 'src/app/components/AdvancedForm/AdvancedForm';
@import 'src/app/components/AdvancedSelect/AdvancedSelect';
@import 'src/app/components/AdvancedRadio/AdvancedRadio';
@import 'src/app/components/AdvancedDateTimePicker/AdvancedDateTimePicker';
@import 'src/app/components/AdvancedCheckbox/AdvancedCheckbox';

@import 'src/app/components/DataTable/DataTable';
@import 'src/app/components/PanelSidebar/PanelSidebar';
@import 'src/app/components/PanelSidebarMenu/PanelSidebarMenu';
@import 'src/app/components/PanelContent/PanelContent';
@import 'src/app/components/PanelContentHeader/PanelContentHeader';
@import 'src/app/components/PanelContentTopBar/PanelContentTopBar';
@import 'src/app/components/PanelContentSearchBar/PanelContentSearchBar';
@import 'src/app/components/PanelContentAdd/PanelContentAdd';
@import 'src/app/components/PanelContentAddItem/PanelContentAddItem';
@import 'src/app/components/Loading/Loading';
@import 'src/app/components/DataTableActions/DataTableActions';
@import 'src/app/components/AdvancedFilters/AdvancedFilters';
@import 'src/app/components/PanelContentBreadcrumb/PanelContentBreadcrumb';
@import 'src/app/components/KPICard/KPICard';
@import 'src/app/components/KPIBar/KPIBar';
@import 'src/app/components/AdvancedInput/AdvancedInput';
@import 'src/app/components/AdvancedFilter/AdvancedFilter';
@import 'src/app/components/AdvancedButton/AdvancedButton';
@import 'src/app/components/AdvancedForm/AdvancedForm';
@import 'src/app/components/AdvancedUploader/AdvancedUploader';
@import 'src/app/components/AdvancedSelect/AdvancedSelect';
@import 'src/app/components/AdvancedRadio/AdvancedRadio';
@import 'src/app/components/AdvancedDateTimePicker/AdvancedDateTimePicker';
@import 'src/app/components/AdvancedCheckbox/AdvancedCheckbox';
@import 'src/app/components/InstitutionalForm/InstitutionalForm';
@import 'src/app/components/DataTable/DataTable';
@import 'src/app/components/PanelSidebar/PanelSidebar';
@import 'src/app/components/PanelSidebarMenu/PanelSidebarMenu';
@import 'src/app/components/PanelContent/PanelContent';
@import 'src/app/components/PanelContentHeader/PanelContentHeader';
@import 'src/app/components/PanelContentTopBar/PanelContentTopBar';
@import 'src/app/components/PanelContentSearchBar/PanelContentSearchBar';
@import 'src/app/components/Loading/Loading';
@import 'src/app/components/DataTableActions/DataTableActions';
@import 'src/app/components/AdvancedFilters/AdvancedFilters';
@import 'src/app/components/PanelContentBreadcrumb/PanelContentBreadcrumb';
@import 'src/app/components/KPICard/KPICard';
@import 'src/app/components/KPIBar/KPIBar';
@import 'src/app/components/MySynodForm/MySynodForm';
@import '/src/app/pages/User/User/Details/UserDetails.less';


@primary-color: #A70228;